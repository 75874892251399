<!-- 照片模板配置弹窗 -->
<template>
  <el-dialog
    width="60%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'照片模板配置':'照片模板配置'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="100px">

      <!--拖车-->
      <div>
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="kuai">
              <div style="display: flex;align-items: center;" class="kuai_l">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;"></span>
                <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">拖车</span>
              </div>
              <div class="kuai_r">
                <el-form-item label="救援地模板">
                  <el-select v-model="form.value" filterable placeholder="请选择">
                    <el-option label="模板1" value="1"/>
                    <el-option label="模板2" value="2"/>
                    <el-option label="模板3" value="3"/>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的地模板">
              <el-select v-model="form.value" filterable placeholder="请选择">
                <el-option label="模板1" value="1"/>
                <el-option label="模板2" value="2"/>
                <el-option label="模板3" value="3"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="bg">
          <div style="font-size: 16px;font-weight: 700;color: #050505;">不合格扣款比例</div>
          <el-row style="margin-top: 15px;">
            <el-col :span="12">
              <el-form-item label="00320/车架号">
                <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="00320/车架号">
                <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px;">
            <el-col :span="12">
              <el-form-item label="00320/车架号">
                <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="00320/车架号">
                <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px;">
            <el-col :span="12">
              <el-form-item label="00320/车架号">
                <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="00320/车架号">
                <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--搭电-->
      <el-row :gutter="15" style="margin-top: 20px;">
        <el-col :span="12">
          <div class="kuai">
            <div style="display: flex;align-items: center;" class="kuai_l">
              <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">搭电</span>
            </div>
            <div class="kuai_r">
              <el-form-item label="救援地模板">
                <el-select v-model="form.value" filterable placeholder="请选择">
                  <el-option label="模板1" value="1"/>
                  <el-option label="模板2" value="2"/>
                  <el-option label="模板3" value="3"/>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="bg">
        <div style="font-size: 16px;font-weight: 700;color: #050505;">不合格扣款比例</div>
        <el-row style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <!--换胎-->
      <el-row :gutter="15" style="margin-top: 20px;">
        <el-col :span="12">
          <div class="kuai">
            <div style="display: flex;align-items: center;" class="kuai_l">
              <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">换胎</span>
            </div>
            <div class="kuai_r">
              <el-form-item label="救援地模板">
                <el-select v-model="form.value" filterable placeholder="请选择">
                  <el-option label="模板1" value="1"/>
                  <el-option label="模板2" value="2"/>
                  <el-option label="模板3" value="3"/>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="bg">
        <div style="font-size: 16px;font-weight: 700;color: #050505;">不合格扣款比例</div>
        <el-row style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="00320/车架号">
              <el-input placeholder="请输入" v-model="form.input2" style="width: 240px;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>



    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped lang="scss">
  .kuai{
    .kuai_l{
      float: left;
      margin-top: 5px;
    }
    .kuai_r{
      float: left;
    }
  }
  .kuai:after{
    content: '';
    clear: both;
    display: block;
  }

  .bg{
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e3e4e9;
    border-radius: 6px;
  }
</style>
